import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBM0SK_SIfTfJNshDwOlVphuTxsW_kpgEo",
  authDomain: "inu-agency.firebaseapp.com",
  projectId: "inu-agency",
  storageBucket: "inu-agency.appspot.com",
  messagingSenderId: "274285787338",
  appId: "1:274285787338:web:cfab19dffc0f06651d5c33",
  measurementId: "G-N8Y5H8G2EH"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };