import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { lang } from '@data/locales';
import { useParams } from "react-router-dom";
import { getDocument } from "@data/firebase/firestore/getData";
import Loading from "@components/Loading";
import GA4React, { useGA4React } from "ga-4-react";
import { useLocation } from "react-router-dom";

const Home = ({ section }) => {
  const location = useLocation();
  const [visitorCountry, setVisitorCountry] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const {id} = useParams();

  const pageLoaded = () => {
    setLoading(false);
    setTimeout(()=> {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }, 100);
  }

  useEffect(()=> {
    if(!id) {
      pageLoaded();
    } else {
     getDocument('links', id, (response) => {
        if(response !== 'error'){
          setData(response); pageLoaded();
          getDocument('models', response.model, (model) => {
            if(response !== 'error'){
              let model_data = {  ...model, ...response };
              setData(model_data); 
            }
          })
        } else {
          pageLoaded();
        }
      });
      //https://ipinfo.io/
      // fetch('https://api.country.is/').then(response => response.json()).then(data => {
      //   setVisitorCountry(data.country);
      // }).catch(error => {
      //   setVisitorCountry('TH');
      // }); 
    }
  }, []);

  const trackLink = (label) => {
    window.gtag('event', 'click', {
      event_category: id,
      event_label: label,
    });
  }
  
  return (
    loading ? <Loading /> : (
      <div className="wrapper">
        {
          data ? (
            <>
              <Helmet>
                <link rel="icon" href={`data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${data.emoji}</text></svg>`} />
                <title>{`${data.nickname} - Links`}</title>
                <meta name="description" content={`Explore the creative world of ${data.name} with her comprehensive collection of links. Discover her captivating content, social media, videos, and more, all in one convenient place.`}/>
                <meta property="og:description" content={`Explore the creative world of ${data.name} with her comprehensive collection of links. Discover her captivating content, social media, videos, and more, all in one convenient place.`}/>
                <meta property="og:image" content={data.banner}/>
              </Helmet>
              <div className="banner-image" style={{ backgroundImage: `url(${data.banner})` }}></div>
              <div className="content">
                <h1>{data.nickname} {data.emoji}</h1>
                {
                  data.twitter && (
                    <a onClick={ () => trackLink('twitter') } href={ `https://twitter.com/${data.twitter}` } target="_blank" className="btn-main">
                      <div className="circle ico-twitter"></div><span>Twitter</span>
                    </a>
                  )
                }
                {
                  data.tiktok && (
                    <a onClick={ () => trackLink('tiktok') } href={ `https://tiktok.com/${data.tiktok}` } target="_blank" className="btn-main">
                      <div className="circle ico-tiktok"></div><span>TikTok</span>
                    </a>
                  )
                }
                {
                  data.reddit && (
                    <a onClick={ () => trackLink('reddit') } href={ `https://www.reddit.com/user/${data.reddit}` } target="_blank" className="btn-main">
                      <div className="circle ico-reddit"></div><span>Reddit</span>
                    </a>
                  )
                }
                {
                  (data.onlyfans) && (
                  //(data.onlyfans && visitorCountry && (!data.geoblock || !data.geoblock.includes(visitorCountry))) && (
                    <a onClick={ () => trackLink('onlyfans') } href={ `https://www.onlyfans.com/${data.onlyfans}` } target="_blank" className="btn-main btn-onlyfans">
                      <div className="circle ico-onlyfans"></div><span>OnlyFans</span>
                    </a>
                  )
                }
                {
                  data.instagram && (
                    <a onClick={ () => trackLink('instagram') } href={ `https://www.instagram.com/${data.instagram}` } target="_blank" className="btn-main">
                      <div className="circle ico-instagram"></div><span>Instagram</span>
                    </a>
                  )
                }
              </div>
            </>
          ) : (
            <div className="not-found">We could not find this user</div>
          )
        }
        
      </div>
    )
  );
}

export default Home;