import { db } from "../";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
//import { collection, query, where, getDocs, doc, addDoc, onSnapshot, orderBy, getDoc } from "firebase/firestore";

export const getDocument =  async (coll, id, callback)  =>  {
    const docRef = doc(db, coll, id);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
        callback(docSnap.data());
    } else {
        callback("error")
    }
};

export const getCollection = async (coll, callback) => {
    const q = collection(db, coll);
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
        let _data = doc.data();
        _data.id = doc.id;
        data.push(_data);
    });
    callback(data);
}
