import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

/*#####PAGES
########################################*/
import Home from "@pages/Home";


const history = createBrowserHistory();
history.listen(location => {
  console.log(location.pathname);
});

function App() {
  return (
    <Routes history={history}>
      <Route exact path={'/'} element={<Home />}/>
      <Route exact path={'/:id'} element={<Home />}/>
      <Route path='*' element={<Home />}/>
    </Routes>
  );
}

export default App;
